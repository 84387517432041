import axios from 'axios';
import { errorHandler } from './requestFull'
// import * as Sentry from "@sentry/nextjs";

const request = axios.create({
  withCredentials: false,
  timeout: 30000, // Set timeout to 5 seconds
  headers: {
    'Content-Type': 'application/json', // Set content type to JSON
    'CF-Access-Client-Id': process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID,
    'CF-Access-Client-Secret': process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET
  },
});

// Add a response interceptor
request.interceptors.response.use(
  response => {
    // Handle successful responses
    return response.data;
  },
  errorHandler
);

export default request;