import ArrowLeft from "@/public/icons/ArrowLeft.svg";
import ArrowDownOutline from "@/public/images/layout/ArrowDownOutline.svg";
import IconRight from "@/public/icons/direction-right.svg";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Button } from "antd";
import { useTranslation } from "next-i18next";
import SubmenuProducts from "./SubmenuProducts";
import SubmenuServices from "./SubmenuServices";
import SubmenuSolutions from "./SubmenuSolutions";
import SubmenuCompany from "./SubmenuCompany";
import SubmenuResources from "./SubmenuResources";
import IconGlobe from "@/public/icons/globe.svg";
import {useScrollDirection} from 'src/hooks/useScrollDirection'

export default function NewHeader() {
  const { t } = useTranslation("header");

  const [currentSubmenu, setCurrentSubmenu] = useState("");
  const [isShowMenuMobile, setIsShowMobile] = useState(false);
  const [isShowLanguage, setIsShowLanguage] = useState(false);

  const handleSetCurrentSubmenu = (value) => {
    setCurrentSubmenu((prev) => {
      if (prev === value) {
        return "";
      }
      return value;
    });
  };

  const showMenuMobile = () => {
    setCurrentSubmenu("");
    setIsShowMobile((prev) => {
      const body = document.getElementsByTagName("body");
      if (isShowMenuMobile) {
        body[0].style.overflow = "auto";
        return !prev;
      }
      body[0].style.overflow = "hidden";
      return !prev;
    });
  };

  const closeSubmenu = () => {
    setCurrentSubmenu("");
  };

  const ref = useDetectClickOutside({ onTriggered: closeSubmenu });
  const refLanguage = useDetectClickOutside({
    onTriggered: () => setIsShowLanguage(false),
  });

  const router = useRouter();
  const { locales, locale } = router;

  useEffect(() => {
    setIsShowMobile(false);
    setCurrentSubmenu("");
    const body = document.getElementsByTagName("body");
    body[0].style.overflow = "auto";
  }, [router.asPath]);

  const handleCloseMenu = () => {
    setIsShowMobile(false);
  };

  const onScroll = useCallback((event, direction) => {
    const headerElement = document.getElementById("layout-cystack-header");

    if (window.scrollY > 150 && direction === 'down') {
      headerElement.style.setProperty("position", "static", "important");
      headerElement.classList.add("animation-fade-in");
    } else if (direction === 'up') {
      headerElement.style.setProperty("position", "fixed", "important");
      headerElement.style.top = "0";
      headerElement.style.left = "0";
      headerElement.style.width = "100%";
      headerElement.classList.remove("animation-fade-in");
    }
  }, []);

  useScrollDirection(onScroll);
  return (
    <header
      id="layout-cystack-header"
      className="cystack-header border border-b border-solid border-l-0 bg-white z-50 border-r-0 border-t-0 border-[#F0F0F0]"
      ref={ref}
    >
      <nav className="relative flex items-center justify-between gap-6 max-w-[1440px] mx-auto px-20 2xl:px-10 xl:px-5 h-full py-3">
        {/* NAVBAR LEFT SECTION */}
        <div className="flex items-center gap-x-8">
          {/* NAVBAR NAVIGATION SECTION */}
          <Link href="/">
            <img
              src="/images/logo-black.svg"
              className="max-w-[110px] object-contain cursor-pointer"
              alt="CyStack logo"
            />
          </Link>
        </div>
        {/*End  Navbar LEFT SECTION */}

        {/* Start Main menu desktop */}
        <ul className="flex items-center flex-1 p-0 m-0 list-none xl:hidden gap-x-4">
          <Link href={"/why-cystack"}>
            <li className="px-2 py-[6px] text-dark-title cursor-pointer font-medium leading-6">
              <span>{t("why_cystack")}</span>
            </li>
          </Link>
          <li className="px-2 py-[6px] text-dark-title  font-medium leading-6">
            <div
              className="cursor-pointer flex items-center gap-[3px]"
              onClick={() => handleSetCurrentSubmenu("products")}
            >
              <span>{t("products.title")}</span>
              <ArrowDownOutline className="w-4 h-4" />
            </div>
            <div
              className={`absolute z-20 bg-white submenu rounded-md overflow-hidden top-[calc(100%)] left-12 right-12 ${
                currentSubmenu === "products"
                  ? "opacity-100 visible border border-solid border-[#D0D5DD] xl:border-none"
                  : "opacity-0 invisible"
              }`}
            >
              <SubmenuProducts />
            </div>
          </li>
          <li className="px-2 py-[6px] text-dark-title font-medium leading-6">
            <div
              className="cursor-pointer flex items-center gap-[3px]"
              onClick={() => handleSetCurrentSubmenu("services")}
            >
              <span>{t("services.title")}</span>
              <ArrowDownOutline className="w-4 h-4" />
            </div>

            <div
              className={`absolute z-20 bg-white submenu rounded-md overflow-hidden top-[calc(100%)] left-12 right-12 ${
                currentSubmenu === "services"
                  ? "opacity-100 visible border border-solid border-[#D0D5DD] xl:border-none"
                  : "opacity-0 invisible"
              }`}
            >
              <SubmenuServices />
            </div>
          </li>
          <li className="px-2 py-[6px] text-dark-title font-medium leading-6">
            <div
              className="cursor-pointer flex items-center gap-[3px]"
              onClick={() => handleSetCurrentSubmenu("solutions")}
            >
              <span>{t("solutions")}</span>
              <ArrowDownOutline className="w-4 h-4" />
            </div>
            <div
              className={`absolute z-20 bg-white submenu rounded-md overflow-hidden top-[calc(100%)] left-12 right-12 ${
                currentSubmenu === "solutions"
                  ? "opacity-100 visible border border-solid border-[#D0D5DD] xl:border-none "
                  : "opacity-0 invisible"
              }`}
            >
              <SubmenuSolutions />
            </div>
          </li>
          <li className="px-2 py-[6px] relative text-dark-title font-medium leading-6">
            <div
              className="cursor-pointer flex items-center gap-[3px]"
              onClick={() => handleSetCurrentSubmenu("company")}
            >
              <span>{t("company")}</span>
              <ArrowDownOutline className="w-4 h-4" />
            </div>
            <div
              className={`absolute -left-8 z-20 bg-white submenu top-[calc(100%+22px)] rounded-md w-max min-w-[290px] ${
                currentSubmenu === "company"
                  ? "opacity-100 visible border border-solid border-[#D0D5DD] xl:border-none"
                  : "opacity-0 invisible"
              }`}
            >
              <SubmenuCompany />
            </div>
          </li>
          <li className="px-2 py-[6px] relative text-dark-title font-medium leading-6">
            <div
              className="cursor-pointer flex items-center gap-[3px]"
              onClick={() => handleSetCurrentSubmenu("resources")}
            >
              <span> {t("resources")}</span>
              <ArrowDownOutline className="w-4 h-4" />
            </div>
            <div
              className={`absolute -left-8 z-20 bg-white submenu top-[calc(100%+22px)] rounded-md w-max min-w-[290px] ${
                currentSubmenu === "resources"
                  ? "opacity-100 visible border border-solid border-[#D0D5DD] xl:border-none"
                  : "opacity-0 invisible"
              }`}
            >
              <SubmenuResources />
            </div>
          </li>
        </ul>

        {/* End Main menu desktop */}

        {/* NAVBAR RIGHT SECTION */}
        <div className={`flex items-center gap-x-4`}>
          <div
            className={`${
              isShowMenuMobile
                ? "sm:fixed sm:block bottom-0 sm:pb-5 left-0 z-[60] sm:bg-white px-5"
                : "sm:hidden"
            } right-0 flex items-center gap-x-3`}
          >
            <Link href="/contact-sales">
              <Button
                onClick={handleCloseMenu}
                size={"large"}
                className="text-[#ffffff] bg-[#141414] font-semibold"
              >
                {t("button.contact_us", { ns: "common" })}
              </Button>
            </Link>
          </div>
          <div className="relative z-50 group w-fit" ref={refLanguage}>
            <IconGlobe
              className="cursor-pointer"
              onClick={() => setIsShowLanguage(!isShowLanguage)}
            />
            <div
              className={`${
                isShowLanguage ? "opacity-100 visible" : "opacity-0 invisible"
              } absolute w-[180px] right-0 top-[105%] bg-white rounded-lg py-1 transition-opacity border border-solid border-[#EAECF0]`}
            >
              {locales?.map((l) => {
                const { pathname, query, asPath } = router;
                return (
                  <div
                    key={"locale-" + l}
                    className={`${l === locale && 'bg-neutral-200'} border border-solid border-l-0 border-r-0 border-t-0 border-[#EAECF0] last:border-none`}
                  >
                    <Link href={{ pathname, query }} as={asPath} locale={l}>
                      <div className={`text-md hover:text-dark-title px-6 py-2 cursor-pointer flex items-center`}>
                        {
                          l === "en" ? <p>English <span className="text-[#888c90]">EN</span></p> :
                           <p>Tiếng Việt <span className="text-[#888c90]">VI</span></p>
                        }
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          {/* MOBILE MENU ICON */}
          <div
            id="nav-icon3"
            onClick={showMenuMobile}
            className={`${isShowMenuMobile && "open"} hidden xl:block`}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
      {/* MOBILE MENU */}
      <div
        className={`${
          isShowMenuMobile ? "is-show" : "right-full"
        } main-menu-mobile fixed bottom-0 left-0 h-[calc(100vh-74px)] sm:h-[calc(100vh-60px)] bg-white z-20`}
      >
        <ul className="p-5 list-none text-dark">
          <Link href="/why-cystack">
            <li className="mb-4 cursor-pointer text-body4">
              {t("why_cystack")}
            </li>
          </Link>
          <li
            onClick={() => handleSetCurrentSubmenu("products")}
            className="flex items-center justify-between w-full mb-4 cursor-pointer gap-x-2 text-body4"
          >
            <span>{t("products.title")}</span>
            <IconRight className="w-6 h-6" />
          </li>
          <li
            onClick={() => handleSetCurrentSubmenu("services")}
            className="flex items-center justify-between w-full mb-4 cursor-pointer gap-x-2 text-body4"
          >
            <span>{t("services.title")}</span>
            <IconRight className="w-6 h-6" />
          </li>
          <li
            onClick={() => handleSetCurrentSubmenu("solutions")}
            className="flex items-center justify-between w-full mb-4 cursor-pointer gap-x-2 text-body4"
          >
            <span>{t("solutions")}</span>
            <IconRight className="w-6 h-6" />
          </li>
          <li
            onClick={() => handleSetCurrentSubmenu("company")}
            className="flex items-center justify-between w-full mb-4 cursor-pointer gap-x-2 text-body4"
          >
            <span>{t("company")}</span>
            <IconRight className="w-6 h-6" />
          </li>
          <li
            onClick={() => handleSetCurrentSubmenu("resources")}
            className="flex items-center justify-between w-full mb-4 cursor-pointer gap-x-2 text-body4"
          >
            <span>{t("resources")}</span>
            <IconRight className="w-6 h-6" />
          </li>
        </ul>
      </div>
      <div
        className={`${
          isShowMenuMobile && currentSubmenu !== "" ? "is-show" : "right-full"
        } submenu-mobile fixed bottom-0 sm:bottom-[150px] left-0 h-[calc(100vh-74px)] sm:h-[calc(100vh-210px)] bg-white z-50`}
      >
        <div className="label border border-solid border-t-0 border-l-0 border-r-0 border-[#dbdbdc] px-5 pb-5 pt-3">
          {currentSubmenu && (
            <div
              onClick={() => setCurrentSubmenu("")}
              className="flex items-center cursor-pointer gap-x-2 text-dark"
            >
              <ArrowLeft className="w-6 h-6" />
              <span>
                {currentSubmenu.charAt(0).toUpperCase() +
                  currentSubmenu.slice(1)}
              </span>
            </div>
          )}
        </div>
        {currentSubmenu === "products" && (
          <div className="px-0">
            <SubmenuProducts />
          </div>
        )}
        {currentSubmenu === "services" && (
          <div className="px-0">
            <SubmenuServices />
          </div>
        )}
        {currentSubmenu === "solutions" && (
          <div className="px-0">
            <SubmenuSolutions />
          </div>
        )}
        {currentSubmenu === "company" && (
          <div className="px-0">
            <SubmenuCompany />
          </div>
        )}
        {currentSubmenu === "resources" && (
          <div className="px-0">
            <SubmenuResources />
          </div>
        )}
      </div>
    </header>
  );
}
