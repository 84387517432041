import { Modal } from "antd";
import IconClose from "@/public/icons/Close.svg";
import Link from "next/link";

export default function CyStackNghiDinh13({ isOpen, closeModal }) {
  return (
    <Modal
      open={isOpen}
      footer={null}
      wrapClassName="form-join-community"
      closeIcon={<IconClose className="text-white" />}
      rootClassName="p-0"
      maskClosable={false}
      onCancel={closeModal}
    >
      <div className="relative max-w-full">
        <div className="absolute -translate-x-1/2 left-1/2 top-20 z-[2] md:max-w-[105%] sm:flex flex-col items-center">
          <img
            src="/images/CyStackND13/HeaderTitle.svg"
            alt="CyStack join community"
            className="sm:max-w-[400px]"
          />
          <img
            src="/images/CyStackND13/Header.svg"
            className="max-w-full"
            alt="CyStack join community"
          />
        </div>

        <img
          src="/images/CyStackND13/MainBackground.svg"
          alt="CyStack join community"
          className="w-[410px] md:max-w-full md:w-auto"
        />
      </div>
      <Link onClick={closeModal} href="/vi/library/checklist-huong-dan-tuan-thu-nd13-phap-ly?utm_source=popup&utm_medium=homepage&utm_campaign=doc2">
        <img
          className="max-w-full cursor-pointer"
          src="/images/CyStackND13/FooterAction.svg"
          alt="CyStack join community"
        />
      </Link>
    </Modal>
  );
}
