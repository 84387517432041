import { useEffect, useState } from "react";

import { Button } from "antd";
import IconGlobe from "@/public/icons/globe.svg";
import Link from "next/link";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

export default function NewHeader() {
  const { t } = useTranslation("header");

  const [currentSubmenu, setCurrentSubmenu] = useState("");
  const [isShowMenuMobile, setIsShowMobile] = useState(false);
  const [isShowLanguage, setIsShowLanguage] = useState(false);

  const handleSetCurrentSubmenu = (value) => {
    setCurrentSubmenu((prev) => {
      if (prev === value) {
        return "";
      }
      return value;
    });
  };

  const showMenuMobile = () => {
    setCurrentSubmenu("");
    setIsShowMobile((prev) => {
      const body = document.getElementsByTagName("body");
      if (isShowMenuMobile) {
        body[0].style.overflow = "auto";
        return !prev;
      }
      body[0].style.overflow = "hidden";
      return !prev;
    });
  };

  const closeSubmenu = () => {
    setCurrentSubmenu("");
  };

  const ref = useDetectClickOutside({ onTriggered: closeSubmenu });
  const refLanguage = useDetectClickOutside({
    onTriggered: () => setIsShowLanguage(false),
  });

  const router = useRouter();
  const { locales, locale } = router;

  useEffect(() => {
    setIsShowMobile(false);
    setCurrentSubmenu("");
    const body = document.getElementsByTagName("body");
    body[0].style.overflow = "auto";
  }, [router.asPath]);

  const handleCloseMenu = () => {
    setIsShowMobile(false);
  };

  const handleGoToTool = () => {
    const element = document.querySelector("#sme-tool");
    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: "smooth",
      top: -300,
    });
  };
  return (
    <header
      className="relative cystack-header border border-b border-solid border-l-0 border-r-0 border-t-0 border-[#F0F0F0]"
      ref={ref}
    >
      <nav className="relative flex items-center justify-between gap-6 max-w-[1440px] mx-auto px-20 2xl:px-10 xl:px-5 h-full py-3">
        {/* NAVBAR LEFT SECTION */}
        <div className="flex items-center gap-x-8">
          {/* NAVBAR NAVIGATION SECTION */}
          <Link href="/">
            <img
              src="/images/logo-black.svg"
              className="max-w-[110px] object-contain cursor-pointer"
              alt="CyStack logo"
            />
          </Link>
        </div>
        {/*End  Navbar LEFT SECTION */}

        {/* Start Main menu desktop */}
        <ul className="flex items-center flex-1 p-0 m-0 list-none xl:hidden gap-x-4">
          <Link href="/#sme-tool">
            <li className="px-2 py-[6px] text-dark-title cursor-pointer font-medium leading-6">
              Công cụ
            </li>
          </Link>
          <li className="relative px-2 py-[6px] text-dark-title cursor-pointer font-medium leading-6 flex items-center gap-2">
            Leaderboard
            <span
              className={`inline-block py-[2px] text-sm px-2 rounded-full border border-solid text-[#B42318] bg-[#FEF3F2] border-[#FECDCA]`}
            >
              Sắp ra mắt
            </span>
          </li>
          <Link href="/posts">
            <li className="px-2 py-[6px] text-dark-title cursor-pointer font-medium leading-6">
              Blog
            </li>
          </Link>
          <li className="px-2 py-[6px] text-dark-title cursor-pointer font-medium leading-6">
            Tài liệu
          </li>
          <Link href={`${process.env.NEXT_PUBLIC_DOMAIN}/vi/platform`} passHref>
            <a target="_blank">
              <li className="px-2 py-[6px] text-dark-title cursor-pointer font-medium leading-6">
                Giải pháp
              </li>
            </a>
          </Link>
        </ul>

        {/* End Main menu desktop */}

        {/* NAVBAR RIGHT SECTION */}
        <div className={`flex items-center gap-x-4`}>
          <div
            className={`${
              isShowMenuMobile
                ? "sm:fixed sm:block bottom-0 sm:pb-5 left-0 z-[60] sm:bg-white px-5"
                : "sm:hidden"
            } right-0 flex items-center gap-x-3`}
          >
            <Link
              href={`${process.env.NEXT_PUBLIC_DOMAIN}/vi/contact-sales`}
              passHref
            >
              <a target="_blank">
                <Button
                  onClick={handleCloseMenu}
                  size={"large"}
                  className="text-[#ffffff] bg-[#141414] font-semibold"
                >
                  {t("button.contact_us", { ns: "common" })}
                </Button>
              </a>
            </Link>
          </div>
          {/* <div className="relative z-50 group w-fit" ref={refLanguage}>
            <IconGlobe
              className="cursor-pointer"
              onClick={() => setIsShowLanguage(!isShowLanguage)}
            />
            <div
              className={`${
                isShowLanguage ? "opacity-100 visible" : "opacity-0 invisible"
              } absolute w-[180px] right-0 top-[105%] bg-white rounded-lg py-1 transition-opacity border border-solid border-[#EAECF0]`}
            >
              {locales?.map((l) => {
                const { pathname, query, asPath } = router;
                return (
                  <div
                    key={"locale-" + l}
                    className="border border-solid border-l-0 border-r-0 border-t-0 border-[#EAECF0] last:border-none"
                  >
                    <Link href={{ pathname, query }} as={asPath} locale={l}>
                      <div className="px-6 py-2 cursor-pointer ">
                        <strong
                          className={`${
                            l === locale
                              ? "font-medium text-dark-title"
                              : "font-normal text-grey"
                          } text-md hover:text-dark-title`}
                        >
                          {l === "en" ? "English" : "Vietnamese"}
                        </strong>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div> */}
          {/* MOBILE MENU ICON */}
          <div
            id="nav-icon3"
            onClick={showMenuMobile}
            className={`${isShowMenuMobile && "open"} hidden xl:block`}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
      {/* MOBILE MENU */}
      <div
        className={`${
          isShowMenuMobile ? "is-show" : "right-full"
        } main-menu-mobile fixed bottom-0 left-0 h-[calc(100vh-74px)] sm:h-[calc(100vh-60px)] bg-white z-20`}
      >
        <ul className="p-5 list-none text-dark">
          <Link href="/#sme-tool">
            <li className="mb-4 cursor-pointer text-body4">Công cụ</li>
          </Link>
          <li className="mb-4 cursor-pointer text-body4">Leaderboard</li>
          <Link href="/posts">
            <li className="mb-4 cursor-pointer text-body4">Blog</li>
          </Link>
          <li className="mb-4 cursor-pointer text-body4">Tài liệu</li>
          <Link href={`${process.env.NEXT_PUBLIC_DOMAIN}/vi/platform`} passHref>
            <a target="_blank">
              <li className="mb-4 cursor-pointer text-body4">Giải pháp</li>
            </a>
          </Link>
        </ul>
      </div>
    </header>
  );
}
