import { useEffect, useRef } from "react";

export const useScrollDirection = (callback) => {
  const lastY = useRef(0);
  const lastDirection = useRef(null);

  useEffect(() => {
    const handleScroll = (event) => {
      const currentY = window.scrollY;
      const newDirection = currentY > lastY.current ? "down" : "up";
      lastDirection.current = newDirection;
      lastY.current = currentY;
      callback(event, newDirection);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [callback]);
};
